
    .inp-wrapper{
        display: flex;
        flex-direction: column;
        .inp-contain{
            display: flex;
            justify-content: center;
            align-items: center;
            .btn-container{
                input{
                    width: 100%;
                }
            }
        }        
        .button_container{
            height: fit-content;
        }
    }
