.popup{
    position: fixed;
    top: 40%;
    z-index: 20;
    min-width: 50%;
    background-color: #f9f9f9;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    
    .popup-content{
        padding: 1.5rem;
        .line{
            display: flex; 
            width: 50%;
            :first-child{
                width: 100px;
            }
        }
        .close{
        position: absolute;
        top: 0.5rem;
        cursor: pointer;
        right: 1.5rem;
        :hover{
            opacity: 0.5;
        }
    }
    }
}